import React from 'react'
import './HomeScreen.css'

export default function HomeScreen() {
  return (
    <div className='home'>
        
        <svg className="doodle" viewBox="0 0 81 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8712 45.9567C16.4531 45.4474 19.1792 45.4456 19.2357 49.5569C19.2811 52.8605 18.6076 56.1649 18.4128 59.4609C17.9957 66.5175 17.9499 72.1072 23.0471 77.2041C32.3722 86.5287 49.1238 87.0282 60.7547 82.6889C67.9214 80.0151 74.2671 74.2839 78.0682 67.6885C80.7636 63.0116 80.2666 59.7345 76.4262 56.0918C72.0712 51.961 67.4279 48.1904 67.0597 41.7158C66.6423 34.374 70.685 28.1183 72.9716 21.4091C74.7614 16.1575 75.4912 9.43892 71.2989 5.07469C66.1453 -0.290242 59.1517 0.30946 52.8643 2.86602C46.1254 5.60612 40.6447 10.8242 33.6144 12.9014C27.9547 14.5737 22.2595 13.4785 16.5512 14.4656C10.3367 15.5403 5.23288 18.9391 2.72595 24.8252C1.01796 28.8354 0.348828 33.9445 1.89154 38.104C2.73893 40.3888 4.69762 41.7066 6.8999 42.4293C9.72015 43.3548 11.699 45.2144 14.7875 45.5769" stroke="currentColor" strokeWidth="1.70404" strokeLinecap="round"></path>
        </svg>
                  
                  
        <div className='home-div'>
            
            <h1 className='typeface'>(<span className='ruita'>RUITA</span>)</h1>
            <h1 className='soon'>Coming Soon</h1>

                  
        </div>
    </div>
  )
}
