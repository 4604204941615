import React from 'react';
import HomeScreen from './comp/HomeScreen';
import './App.css';

function App() {
  return (
    <> 
      <HomeScreen />
    </>
  );
}

export default App;
